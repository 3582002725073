@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,200;0,300;1,200;1,300&display=swap');

body{
  font-family: 'Josefin Sans', sans-serif!important;
}
.App {
  text-align: center;
  width: 350px;
  height: 600px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  background: white;
}

.frontPageLogo {
  width: 30%;
}

.frontPageButton {
  width: 90%;
  margin-top: 20px;
}

.frontPageBottom {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  gap: 5px;
}

.frontPageBottom:hover {
  cursor: pointer;
  color: gray;
}

h2 {
  font-size: 40px;
  margin-bottom: 5px;
  color: #0B0066;
}

.h4 {
  color: #0B0066;
  padding-bottom: 50px;
}

a:visited {
  color: blue;
}

header {
  width: 100%;
  height: 80px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.headerLogo {
  height: 89px;
  margin-left: -7px;
}

.dropdown {
  margin-right: 20px;
}

.content {
  text-align: center;
  width: 350px;
  height: 520px;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mnemonic {
  color: #faad14;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  padding: 5px;
  text-align: justify;
  width: 310px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.seedPhraseContainer {
  width: 310px;
  min-height: 160px;
  padding: 5px;
  margin-top: 30px;
}

.walletName {
  font-weight: bold;
  margin-bottom: 5px;
}

.logoutButton {
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 20px;
  top: 5px;
  border-radius: 100px;
}

.logoutButton:hover {
  background-color: rgba(0, 0, 0, 0.036);
  cursor: pointer;
}

.walletView {
  width: 350px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.sendRow {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.nftImage {
  margin-bottom: 10px;
  height: 240px;
  width: 240px;
  object-fit: cover;
  border-radius: 10px;
}

:where(.css-dev-only-do-not-override-1xusghl).ant-tabs{
  color: #0B0066;
}