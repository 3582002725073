@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,200;0,300;1,200;1,300&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(	https://itishstudios.net/assert/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-divider {
  margin-bottom: 2px !important;
}

.ant-tabs-nav-wrap {
  justify-content: center !important;
}

body{
  font-family: 'Josefin Sans', sans-serif!important;
  color: #0B0066!important;
}
.cw{
  color: #0B0066!important;
}
:where(.css-1xusghl).ant-tabs .ant-tabs-tab-btn{
  color: #0B0066!important;
}


:where(.css-dev-only-do-not-override-1xusghl).ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
  color: black;
  font-size: 11px;
  line-height: 1.571429;
  /* padding-top: -4px; */
}

.tokens{
  margin-top: -24px;
}

.list{
  cursor: pointer;
}

.vaue{
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
